import React, { Component } from 'react'
import './index.scss';

class Repositories extends Component {
	state = {
	}
	render() {
		return (
			<div>
				Repositories
    	</div>
		)
	}

}
export default Repositories;
